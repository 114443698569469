import "./styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect } from "react";

import NFTax from "./views/NFTax.js";
import NFTaxNavbar from "./views/NFTaxNavbar.js";
import { Mixpanel } from "./util/Mixpanel";

function App() {
  useEffect(() => {
    Mixpanel.track("NFTax visited");
  }, []);

  return (
    <body>
      <NFTaxNavbar />
      <NFTax />
    </body>
  );
}

export default App;
