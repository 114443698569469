import { Container, Navbar } from "react-bootstrap";

const NFTaxNavbar = () => {
  return (
    <Container fluid>
      <Navbar>
        <Container fluid>
          <Navbar.Brand href="/"> 💸 NFTax </Navbar.Brand>
        </Container>
      </Navbar>
    </Container>
  );
};

export default NFTaxNavbar;
