import axios from 'axios';

const ASSET_ENDPOINT = "https://api.opensea.io/api/v1/assets";

const getAssets = (ethAddr, callback, callbackOnError) => {
  axios.get(ASSET_ENDPOINT + "?owner=" + ethAddr)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      callbackOnError(error);
    });
}

export { getAssets };
