import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import Discord from "./Discord";

const CONTROLLER = require("../controllers/nftAssetsController.js");

const COLORS = [
  "#f94144",
  "#f3722c",
  "#f9844a",
  "#f8961e",
  "#f9c74f",
  "#90be6d",
  "#43aa8b",
  "#4d908e",
  "#577590",
  "#277da1",
];
// Single -> 0
// Married filing jointly -> 1
// Married, filing separately -> 2
// Head of household -> 3
const SHORT_TAXRATES = [
  [9875, 40125, 85525, 163300, 207350, 518400],
  [19750, 80250, 171050, 326600, 414700, 622050],
  [9875, 40125, 85525, 163300, 207350, 311025],
  [14100, 53700, 85500, 163300, 207350, 518400],
  [0.1, 0.12, 0.22, 0.24, 0.32, 0.35, 0.37],
];

const LONG_TAXRATES = [
  [40000, 441450],
  [80000, 496600],
  [40000, 248300],
  [53600, 469050],
  [0, 0.15, 0.2],
];

const NFTax = () => {
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [prevYearIncome, setPrevYearIncome] = useState(0);
  const [timePurchaseSale, setTimePurchaseSale] = useState();
  const [taxFiling, setTaxFiling] = useState();

  const calculatePretaxGains = (salePrice, purchasePrice) => {
    return salePrice - purchasePrice;
  };

  const calculateTaxableIncome = (prevYearIncome, salePrice, purchasePrice) => {
    //prevYearIncome -
    return calculatePretaxGains(salePrice, purchasePrice);
  };

  const calculateTaxRate = (timePurchaseSale, taxFiling, prevYearIncome) => {
    let taxArray;
    if (timePurchaseSale === 0) taxArray = SHORT_TAXRATES;
    else taxArray = LONG_TAXRATES;
    var tempArr = taxArray[taxFiling];
    const tempArray = tempArr.filter((upperLim) => upperLim <= prevYearIncome);
    return taxArray[4][tempArray.length];
  };

  // TODO: fix this to get real time tax based on values from dropdown
  useEffect(() => {
    if (
      prevYearIncome &&
      salePrice &&
      purchasePrice &&
      timePurchaseSale !== undefined &&
      taxFiling !== undefined
    ) {
      console.log(
        "tax rate",
        calculateTaxRate(timePurchaseSale, taxFiling, prevYearIncome)
      );
      let tempTotal =
        calculateTaxableIncome(prevYearIncome, salePrice, purchasePrice) *
        calculateTaxRate(timePurchaseSale, taxFiling, prevYearIncome);
      console.log(tempTotal);
      setTotal(Math.max(tempTotal, 0));
    }
  }, [prevYearIncome, salePrice, purchasePrice, timePurchaseSale, taxFiling]);

  return (
    <Container style={{ marginTop: "25px" }}>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h1
            style={{ color: "#06d6a0", fontWeight: "bold", fontSize: "400%" }}
          >
            Calculate your taxes on your NFTs sold
          </h1>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={2}></Col>

        <Col md={8} style={{ marginBottom: "20px" }}>
          <Row>
            <Col>
              <InputGroup>
                <Form.Control
                  placeholder="$0"
                  onChange={(e) => {
                    setPurchasePrice(e.target.value);
                  }}
                />
              </InputGroup>
              <div style={{ height: "10px" }}></div>
              <Form.Text muted>Enter your purchase price (required)</Form.Text>
            </Col>
            <Col>
              <InputGroup>
                <Form.Control
                  placeholder="$0"
                  onChange={(e) => {
                    setSalePrice(e.target.value);
                  }}
                />
              </InputGroup>
              <div style={{ height: "10px" }}></div>
              <Form.Text muted>Enter your sale price (required)</Form.Text>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <h3>Your pre-tax capital gain is:</h3>
              <h4>${calculatePretaxGains(salePrice, purchasePrice)}</h4>
            </Col>
            <Col>
              <InputGroup>
                <Form.Control
                  placeholder="$0"
                  onChange={(e) => {
                    setPrevYearIncome(e.target.value);
                  }}
                />
              </InputGroup>
              <div style={{ height: "10px" }}></div>
              <Form.Text muted>
                Enter your 2020 taxable income (excluding the capital gain)
                (required)
              </Form.Text>
            </Col>
          </Row>
          <br />

          <Row>
            <Col>
              <h3>
                Your total taxable income, including your capital gain, is:
              </h3>
              <h4>
                $
                {calculateTaxableIncome(
                  prevYearIncome,
                  salePrice,
                  purchasePrice
                )}
              </h4>
            </Col>
            <Col></Col>
          </Row>
          <br />

          <Row>
            <Col>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Time of Purchase to Sale
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => {
                      setTimePurchaseSale(0);
                    }}
                  >
                    One Year or Less
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setTimePurchaseSale(1);
                    }}
                  >
                    More than one year
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Form.Text muted>
                Enter the time from purchase to sale (required)
              </Form.Text>
            </Col>
            <Col>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Tax-filing status
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => {
                      setTaxFiling(0);
                    }}
                  >
                    Single
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setTaxFiling(1);
                    }}
                  >
                    Married, filing jointly
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setTaxFiling(2);
                    }}
                  >
                    Married, filing separately
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setTaxFiling(3);
                    }}
                  >
                    Head of household
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Form.Text muted>
                Enter your tax-filing status (required)
              </Form.Text>
            </Col>
          </Row>
          <br />
          {loading ? (
            <Row style={{ marginTop: "50px" }}>
              <Col style={{ textAlign: "center" }}>
                <Spinner animation="border" variant="info" />
              </Col>
            </Row>
          ) : (
            <>
              <Row style={{ marginTop: "50px" }}>
                <Col style={{ textAlign: "center" }}>
                  <h1 style={{ fontSize: "400%" }}>
                    NFTax: <b>{total ? `$${total}` : "?"}</b>
                    <sup style={{ fontSize: "40px", color: "grey" }}>
                      *
                    </sup>{" "}
                    <span style={{ fontFamily: "Sans-serif" }}></span>
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    color: "grey",
                  }}
                >
                  * this is just an estimate and not financial advice
                </Col>
              </Row>
            </>
          )}
        </Col>

        <Col xs={2}></Col>
      </Row>
      <br />
      <br />
      <Discord />
    </Container>
  );
};

export default NFTax;
